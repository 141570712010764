

export function Footer(){
    return(
            <footer>
                For Estimates or Inquiries, Please Use The Following Contact information. 
                <br/><t/>Email: CascadiaLandscape@gmail.com 
                <br/><t/><a href='tel:916-343-0234' style={{color: '#FFFFFFA0'}}>Phone: (916) 343-0234</a>
            </footer>
        );
    }