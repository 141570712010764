import React, { useState, useEffect } from "react";
import '../css/Gallery.css'

import { useSwipeable } from "react-swipeable";


const FullscreenImage = ({ image, index, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Reset index when the image changes
    setCurrentIndex(index);
  }, [image]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % image.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + image.length) % image.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="fullscreen-modal">
      <span className="close-btn" onClick={onClose}>&times;</span>
      <img src={image[currentIndex]} alt="Fullscreen" className="fullscreen-image" />
      <div className="nav-arrows">
        <span onClick={handlePrev}>&lt;</span>
        <span onClick={handleNext}>&gt;</span>
      </div>
    </div>
  );
};

export default FullscreenImage;