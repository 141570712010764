import React from 'react';
import { Header } from './Header';
import '../css/Services.css';
import { Footer } from './Footer';
import job1 from '../images/20200117_142640.jpg';
import job2 from '../images/20200924_115116.jpg';
import job3 from '../images/20220303_155024 - Copy.jpg';
import job4 from '../images/IMG_20231002_162510_01.jpg';
import job5 from '../images/20220519_104353.jpg';
import job6 from '../images/20190614_102932.jpg';
import stairs from '../images/20190606_131905.jpg'
import pergola from '../images/20201201_101627.jpg'
import planter from '../images/20200622_095659.jpg'
import putting from '../images/20200227_114040.jpg'
import creek from '../images/20200819_120441.jpg'
import grass from '../images/20200317_165601.jpg'
import tree from '../images/dactylifera-8.jpg'
import kitchen from '../images/20230901_144937.jpg'
import snakes from '../images/snakefencing.png'
import walls from '../images/20200107_155305.jpg'
import stain from '../images/20230901_151046.jpg'
import bocce from '../images/bocce.png'

const serviceGrid = {
  Concrete: {
    Image: job1,
    Title: 'Concrete',
    Description: ''
  },
  Masonry: {
    Image: job2,
    Title: 'Masonry',
    Description: ''
  },
  WaterFeatures: {
    Image: job3,
    Title: 'Water Features',
    Description: ''
  },
  Landscape: {
    Image: job6,
    Title: 'Landscaping',
    Description: ''
  },
  FirePits: {
    Image: job4,
    Title: 'Fire Pits',
    Description: ''
  },
  Structures: {
    Image: job5,
    Title: 'Structures',
    Description: ''
  },
  SnakeFencing: {
    Image: snakes,
    Title: 'Snake Fencing',
    Description: ''
  },
  Stairways: {
    Image: stairs,
    Title: 'Stairways',
    Description: ''
  },
  OutdoorKitchen: {
    Image: kitchen,
    Title: 'Outdoor Kitchens',
    Description: ''
  },
  ArtificialGrass: {
    Image: grass,
    Title: 'Grass',
    Description: ''
  },
  BocceBall: {
    Image: bocce,
    Title: 'Bocce Ball',
    Description: ''
  },
  TreeInstallation: {
    Image: tree,
    Title: 'Tree Installation',
    Description: ''
  },
  DryCreekBeds: {
    Image: creek,
    Title: 'Dry Creek Beds',
    Description: ''
  },
  Pergolas: {
    Image: pergola,
    Title: 'Pergolas',
    Description: ''
  },
  Walls: {
    Image: walls,
    Title: 'Walls',
    Description: ''
  },
  Staining: {
    Image: stain,
    Title: 'Staining',
    Description: ''
  },
  Planters: {
    Image: planter,
    Title: 'Planters',
    Description: ''
  },
  Putting: {
    Image: putting,
    Title: 'Putting Greens',
    Description: ''
  }

  // ,
  // SnakeFencing: {
  //   Image: job8,
  //   Title: 'Snake Fencing',
  //   Description: ''
  // }
};

export default function Services() {
  return (
    <div className="Page">
      <Header/>
      <div className='grid'>
        {Object.values(serviceGrid).map((topic, idx) => {
          return (
            <div key={idx} className='grid-item' onClick={console.log('clicked')}>
              <div>
                <img src={topic.Image} alt={topic.Title} />
              </div>
              <h2>{topic.Title}</h2>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
}