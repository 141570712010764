import '../css/Quotes.css'
import '../css/Common.css'
import { Header } from './Header';
import { Footer } from './Footer';

export default function Quotes() {
    return (
        <div>
            <div className="Page">
                <Header/>

                <div className='Input-Body'>
                    <h1>
                        Get A Quote
                    </h1>
                    <div className="Input-Row">
                        <div className='Input-Field'>
                            {/* First Name */}
                            <input type="text" class="grayed-input" placeholder={"First Name*"}/>
                        </div>
                        <div className='Input-Field'>
                            {/* Last Name */}
                            <input type="text" class="grayed-input" placeholder={"Last Name"}/>
                        </div>
                    </div>

                    <div className="Input-Row">
                        <div className='Input-Field'>
                            {/* Email */}
                            <input type="text" class="grayed-input" placeholder={"Email*"}/>
                        </div>
                        <div className='Input-Field'>
                            {/* Phone */}
                            <input type="text" class="grayed-input" placeholder={"Phone Number"}/>
                        </div>
                    </div>

                    <div className="Input-Row">
                        <div className='Input-Field'>
                            {/* Project Details */}
                            <input type="text" class="grayed-input" placeholder={"What type of project are you starting?"}/>
                        </div>
                        <div className='Input-Field'>
                            {/* Referral */}
                            <input type="text" class="grayed-input" placeholder={"How did you hear about us?"}/>
                        </div>
                    </div>

                    <div className="Input-Row">
                        <div className='Input-Field'>
                            {/* Subject */}
                            <input type="text" class="grayed-input" placeholder={"Subject*"}/>
                        </div>
                    </div>

                    <div className='Input-Row'>
                        <div className="Input-Field">
                                {/* Project Description */}
                            <div className="Message-Body">
                                <input type="text" class="grayed-input" placeholder={'Project description'}></input>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button>
                    Get Quote
                </button> */}
            </div>
            <Footer/>
        </div>
    );
}