import {IoIosMenu} from 'react-icons/io';
import {DropDownLi, Dropbtn, DropDownContent, SubA} from '../utilities/StyledDivs';
import { Link } from 'react-router-dom';
import "../css/Home.css";

export function DropDownMenu(){

    return(
        <DropDownLi>
            <Dropbtn>
                {<IoIosMenu color='white' size={'3vh'}/>}
            </Dropbtn>
            <DropDownContent>
                <Link to={'/'} className="link">
                    <SubA>Home</SubA>
                </Link>

                <Link to={'/Portfolio'} className="link">
                    <SubA>Gallery</SubA>
                </Link>
                
                <Link to={'/Services'} className="link">
                    <SubA>Services</SubA>
                </Link>

                {/* <Link to={'/Quotes'} className="link">
                    <SubA>Quotes</SubA>
                </Link> */}

                <Link to={'/About'} className="link">
                    <SubA>About Us</SubA>
                </Link>

                <Link to={'/Contact'} className="link">
                    <SubA>Contact</SubA>
                </Link>

            </DropDownContent>
        </DropDownLi>
    );
}