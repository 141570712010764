import { Gallery } from "./Gallery";
import {Header} from "./Header";
import '../css/Portfolio.css'
import { Footer } from "./Footer";

export default function Portfolio() {
    return (
        <div className="Page">
            <Header/>
            <Gallery/>
            <Footer/>
        </div>
    );
}