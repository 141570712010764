import "../css/Home.css";
import logo from '../images/Cascadia Landscape (2).svg';
import title from '../images/Title.png';

import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <div className="LandingPage">
            <div className="Content">
                <header>

                    <Link to='/Portfolio' className="link">
                        <div class="Header-Tab">                       
                            Gallery
                        </div>
                    </Link>

                    <Link to='/Services' className="link">
                        <div class="Header-Tab">                       
                            Services
                        </div>
                    </Link>

                    {/* <Link to='/Quotes' className="link">
                        <div className="Header-Tab">
                            Quotes
                        </div>
                    </Link> */}

                    <Link to='/About' className="link">
                        <div className="Header-Tab">
                            About Us
                        </div>
                    </Link>

                    <Link to='/Contact' className="link">
                        <div className="Header-Tab">
                            Contact
                        </div>
                    </Link>
                </header>
                <div className="Logo-Container-lp">
                        {/*<img src={logo} className="Cascadia-logo-lp" alt="logo" />*/}
                        <img src={logo} className="Cascadia-logo-lp" alt="logo" />
                </div>

                <div className="Title-Container fadeIn">
                    
                    <div className="Title-Text-Container">
                        {/* <h1>Premier Landscapes</h1> */}
                        <h1>Make Your Dreams a Reality</h1>
                    </div>
                    
                    <div className="SubTitle-Container">
                        <div className="Info">
                            <h2>El Dorado Hills, CA<br/>
                                <div style={{color:'rgba(255,255,255,0.6)'}}>License #1105283</div>  
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="Get-Quote-Button-Container fadeIn">
                    <Link to='/Contact' className="link">           
                        <button>Get A Quote</button> 
                    </Link>
                </div> 

                {/* <div className="Text-Container fadeIn">
                    <p>
                        More flushed out and descriptive section used to give a quick 
                        and concise description of the company while not taking up too much space
                    </p>
                </div> */}



                {/* <div className="See-Portfolio-Button-Container fadeIn">
                    <div>See Our Work</div>
                </div>

                <div className="See-Full-Website-Button-Container fadeIn">
                    <button>Continue To Full Website</button>
                </div> */}
            </div>
        </div>
    );
}