// import card from './Cascadia Landscape Bus card.svg';
// import logo from './logo.png';
// import title from './Title.png';

// import './App.css';
// import {AiFillCaretRight, AiFillCaretLeft} from 'react-icons/ai';
// import { DropDownMenu } from './DropDownMenu';

// import React, { useState } from 'react';
// import { Slideshow } from './Slideshow';
// import { LandingPage } from './LandingPage';

// function App() {
//   const [count, setCount] = useState(5);
//   const [isLanded, setLanded] = useState(false);

//   if(isLanded){
//     return (
//       <div className="App">
//         <header className="App-header">
//           <div className='Logo-Container'>
//             <img src={logo} className="Cascadia-logo" alt="logo" />
//             <img src={title} className="Cascadia-title" />
//           </div>
//           <div className="Dropdown-Menu">
//             <DropDownMenu/>
//           </div>
//         </header>
//         <div className='Home-Page'>
//           <div>
//             <Slideshow></Slideshow>
//             {/* <AiFillCaretLeft onClick={IncrementCount} className='next-button'/>
//             <img src={yards[Math.abs(count) % 8]} className="yard-image" alt=""/>
//             <AiFillCaretRight onClick={DecrementCount}className='next-button'/> */}
//           </div>

//           <div className='main-text'>
//             <h2>OUR PRODUCT SPEAKS FOR ITSELF</h2>
//             <h2>PREMIER LANDSCAPE CONTRACTOR</h2>
//             <p>
//               EL DORADO HILLS, CA
//               Cascadian Landscape is the premier landscape designer and landscape contractor in Northern California. We are committed to designing and producing quality custom outdoor living environments and making your outdoor living space dreams a reality. We guarantee exceptional products and services and provide complete turnkey solutions. If you would like to convert your current landscape to something spectacular while maintaining your unique personal influence, we will happily work with you to meet your budget and surpass your landscaping aspirations.

//               If you are in search of a landscape designer to create a relaxing new pool, an elegant outdoor space, or add that special touch to refresh the look of your property, look no further. Cascadian Landscape is Sacramento area’s preferred landscape designer. We will beautify and enhance your surroundings and we promise you will not be disappointed. We look forward to providing you with the best possible landscaping experience.

//               Looking for landscape design ideas? Be sure to check our landscape portfolio. We are now featured on Thumbtack.com for Sacramento landscape designers.
//             </p>
//           </div>

//           <footer>
//             For Estimates or Inquiries, Please Use The Following Contact information. 
//             <br/><t/>Email: CascadiaLandscape@gmail.com 
//             <br/><t/>Phone: (916) 343-0234
//           </footer>
//         </div>
//       </div>
//     );
//   }else{
//     return(
//       <LandingPage/>
//     )
//   }
// }
// export default App;


import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Quotes from './components/Quotes';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="Services" element={<Services/>}/>
        <Route path="Portfolio" element={<Portfolio />} />
        <Route path="Quotes" element={<Quotes />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
