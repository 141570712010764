import '../css/Common.css'
import '../css/Contact.css'

import { Footer } from "./Footer";
import { AiOutlinePhone, AiOutlineInstagram } from "react-icons/ai";
import { BiMap } from "react-icons/bi"
import { BsChatLeftText, BsEnvelope } from "react-icons/bs"

import {Header} from '../components/Header'
import {Map} from '../components/Map'

import logo from '../images/logo.png';
import title from '../images/Title.png';

export default function Contact() {
    return (
        <div>
            <Header/>
            <div className="Contact-Page">
                
                <div className="Title">
                    <h1>Contact Us</h1>
                </div>

                <div className="Sub-Title">
                    <p>Get in touch with us to begin designing your dream landscaping project</p>
                </div>
                <div className="Contact-Body">
                    <div className="Contact-Section-1">
                        <a href='tel:916-343-0234'>
                            <div className="Contact-Icon">
                                <AiOutlinePhone size={'40%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Phone</h3>
                            <br />
                            <a href='tel:916-343-0234'>916-343-0234</a>
                        </div>
                    </div>

                    {/* <div className="Contact-Section">
                        <div className="Contact-Icon">
                            <BiMap size={'40%'} color='#FFFFFF90'/>
                        </div>
                        <div className="Contact-Description">
                            Location:
                            <br />
                            Your Address Here
                        </div>
                    </div> */}

                    <div className="Contact-Section-2">
                        <a href='mailto:CascadiaLandscape@Gmail.com'>
                            <div className="Contact-Icon">
                                <BsEnvelope size={'25%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Email</h3>
                            <br />
                            <a href='mailto:CascadiaLandscape@Gmail.com'>CascadiaLandscape@Gmail.com</a>
                        </div>
                    </div>

                    <div className="Contact-Section-3">
                        <a href={"https://instagram.com/cascadialandscape?igshid=OGQ5ZDc2ODk2ZA=="}>
                            <div className="Contact-Icon">
                                <AiOutlineInstagram size={'40%'} color='#e4e4e4'/>
                            </div>
                        </a>
                        <div className="Contact-Description">
                            <h3>Instagram</h3>
                            <br />
                            <a href={"https://instagram.com/cascadialandscape?igshid=OGQ5ZDc2ODk2ZA=="}>@CascadiaLandscape</a>
                        </div>
                    </div>
                </div>
                {/* <div className="Business-Card">
                    
                </div> */}

                {/* <div className='Logo-Section'>
                    <img src={logo} className="Cascadia-logo" alt="logo" />
                    <img src={title} className="Cascadia-Title-Section" />
                    <h2>California License #1105283</h2>
                    <img src={logo} className="Cascadia-Logo-Image" alt="logo" />
                </div> */}

                <div className="Location-Section">
                    {/* <div className="Location-Title">
                        <h2>Find Us</h2>
                    </div>
                    <div className="Map">
                        <Map width={'100%'} height={'30vh'}/>
                    </div> */}
                </div>

            </div>
            <Footer/>
        </div>
    );
}