import { Footer } from "./Footer";
import { Header } from "./Header";
import '../css/Common.css'
import logo from '../images/logo.png';
import stock from '../images/dactylifera-8.jpg'
import stock2 from '../images/IMG_20231002_161419_01.jpg'
import job4 from '../images/IMG_20231002_162459_01.jpg';
import '../css/About.css'

export default function About() {
    return (
        <>

            <div className="Page">
            <Header/>
                <div className="About-Page">       
            
                    <div className="Intro-Section">
                        <div className="flex-row">
                            {/* <img src={logo} className="Cascadia-logo"></img> */}
                            <h2>Cascadia's Story</h2>
                        </div>
                        <div className="Intro-Synopsis">
                            Get to know Cascadia Landscape — from its roots in generational expertise to its commitment to crafting exquisite outdoor spaces that blend nature and design harmoniously.
                        </div>
                    </div>
                    <div className="Info-Row">
                        <div className="Image-Section">
                            <img src={stock} width={'100%'} alt="Placeholder" />
                        </div>
                        <div className="Text-Section">
                            <h2>Our Founding</h2>
                            <p>
                                Cascadia Landscape, a second-generation landscape & hardscape company, was established by Sean Delong, who brought decades of combined experience to the table. 
                                With a commitment to quality and excellence in design, Cascadia Landscape has become a trusted name in the industry. Founded on the principles of hard work and a passion for creating beautiful outdoor spaces, 
                                Sean and his team have consistently delivered top-notch landscape services that enhance the natural beauty of the Greater Sacramento Area. 
                                Cascadia Landscape's dedication to quality design is evident in every project they undertake, making them the best choice for all your landscaping needs.

                                <br/><br/>

                                Join us in the journey of transforming your outdoor vision into reality. Cascadia Landscape is a blend of tradition and innovation, offering a personalized touch to each project we undertake. 
                                With a nod to Cascadian Landscapes's legacy, we're dedicated to delivering quality landscape services that speak for themselves.
                                    {/* Cascadia Landscape is a family-owned business nestled in the heart of El Dorado Hills, California. Founded by Sean Delong, our journey is deeply rooted in a legacy of landscaping excellence. 
                                    Sean's father, Robert Delong, dedicated more than three decades to his landscaping business, and it is this background that inspired Sean to establish Cascadia as a tribute to his father's dedication.
                                    <br/>
                                    With a collective experience of half a century in landscape design, Cascadia Landscape has established itself as a respected player in the greater Sacramento area. 
                                    Our focus on quality and attention to detail are at the core of what we do. We take pride in our ability to create refined, high-end luxury landscaping projects that seamlessly blend aesthetics and functionality.
                                    <br/>
                                    Our philosophy centers around crafting outdoor spaces that resonate with the unique aspirations of our clients. 
                                    We believe that landscaping is an art form, and our work reflects a harmonious synthesis of nature and design. 
                                    From thoughtfully arranging plants to creating inviting hardscapes, each project showcases our commitment to creating spaces that truly enhance the outdoor living experience.
                                    <br/>
                                    Join us in the journey of transforming your outdoor vision into reality. Cascadia Landscape is a blend of tradition and innovation, offering a personalized touch to each project we undertake. 
                                    With a nod to Robert Delong's legacy, we're dedicated to delivering quality landscaping services that speak for themselves. */}
                            </p>
                        </div>
                    </div>

                    <div className="Info-Row-alt">
                        <div className="Text-Section">
                            <h2>Cascadia's Mission</h2>
                            <p>
                                At Cascadia Landscape, our mission is to create outdoor spaces that evoke beauty and tranquility, all while aligning with the unique visions of our clients. With a deep-seated respect for our client's vision and a keen eye for design, we aim to transform your outdoor dreams into reality. Our commitment to quality craftsmanship and thoughtful detailing ensures that every project we undertake reflects our dedication to excellence.
                            </p>
                        </div>
                        <div className="Image-Section">
                            <img src={job4} width={'100%'} alt="Placeholder" />
                        </div>
                    </div>

                    <div className="Info-Row">
                        <div className="Image-Section">
                            <img src={stock2} width={'100%'} alt="Placeholder" />
                        </div>
                        <div className="Text-Section">
                            <h2>Cascadia's Goals for the Future</h2>
                            <p>
                                As we look to the future, Cascadia Landscape envisions expanding our reach while maintaining the personalized grass roots approach that defines us. We aspire to continue growing as a trusted name in the landscape & hardscape industry, striving to bring innovation, sustainability, and beauty to every project. Our goal is to create outdoor spaces that not only elevate aesthetics but also enhance the lives of those who experience them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}