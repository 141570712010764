import React, { useState, useEffect } from "react";
import '../css/Gallery.css'
import FullscreenImage from "./FullScreenImage.js";

import con1 from '../images/IMG_20231002_162445_01.jpg'
import con2 from '../images/20230526_171642.jpg'
import con3 from '../images/20210909_092646.jpg'
import con4 from '../images/20200117_142640.jpg'
import con5 from '../images/20221110_104712.jpg'
import con6 from '../images/20230526_171907.jpg'
import con7 from '../images/20230830_100346.jpg'
import con8 from '../images/20190508_113623.jpg'


import mas1 from '../images/20220303_155024.jpg'
import mas2 from '../images/20210721_144633.jpg'
import mas3 from '../images/20200227_120434.jpg'
import mas4 from '../images/20200903_162445.jpg'
import mas5 from '../images/20191217_123612.jpg'
import mas6 from '../images/20200925_114344.jpg'
import mas7 from '../images/20200925_114141.jpg'
import mas8 from '../images/20200924_115116.jpg'


import wat1 from '../images/20220303_155024 - Copy.jpg'
//import wat2 from '../images/IMG_20231002_162546_01.jpg'
//import wat3 from '../images/IMG_20231002_162550_01.jpg'
//import wat4 from '../images/20221121_115534.jpg'
//import wat5 from '../images/IMG_20231002_162555_01.jpg'
// import wat6 from '../images/20210513_130832.jpg'
//import wat6 from '../images/20200724_131114.jpg'
// import wat8 from '../images/20200513_143625.jpg'
//import wat7 from '../images/20181212_122731.jpg'
// import wat10 from '../images/20230316_111800.jpg'

import fir1 from '../images/Snapchat-122337854.jpg'
import fir2 from '../images/20230720_134457.jpg'
import fir3 from '../images/20230316_111800.jpg'
import fir4 from '../images/20221128_124740.jpg'
import fir5 from '../images/20220517_113941.jpg'
import fir6 from '../images/20220330_155131.jpg'
import fir7 from '../images/20220128_141430.jpg'
import fir8 from '../images/20200227_114154.jpg'

import str0 from '../images/IMG_20230217_173501.jpg'
import str1 from '../images/20190614_102932.jpg'
import str2 from '../images/20191025_152531.jpg'
import str3 from '../images/20200428_122714.jpg'
import str4 from '../images/20200602_111157.jpg'
import str5 from '../images/20200622_095501.jpg'
import str6 from '../images/20201201_101627.jpg'
import str7 from '../images/20210629_122108.jpg'
import str8 from '../images/20220301_104117.jpg'
import str9 from '../images/20220310_104753.jpg'
import str10 from '../images/20220504_122735.jpg'
import str11 from '../images/20220519_104353.jpg'
import str12 from '../images/20220915_104612.jpg'
import str13 from '../images/20221121_115052.jpg'
import str14 from '../images/20230530_143751.jpg'

import lan1 from '../images/20190606_131804.jpg'
import lan2 from '../images/20190508_115936.jpg'
import lan3 from '../images/20220128_141636.jpg'
import lan4 from '../images/20190614_102932.jpg'
import lan5 from '../images/20191217_122434.jpg'
import lan6 from '../images/20200320_152117.jpg'
import lan7 from '../images/20220311_111959.jpg'
import lan8 from '../images/20200507_151255.jpg'
import lan9 from '../images/20200819_115907.jpg'
import lan10 from '../images/20220316_092917.jpg'
import lan11 from '../images/20220601_123210.jpg'
import lan12 from '../images/20220620_122444.jpg'
import lan13 from '../images/Apple Iphone Photos 047.jpg'

const categories = {
  All: [con1, con2, con3, con4, con5, con6, con7, con8, mas1, mas2, mas3, mas4, mas5, mas6, mas7, mas8, wat1, fir1, fir2, fir3, fir4, fir5, fir6, fir7, fir8, str0, str1, str2, str3, str4, str5, str6, str7, str8, str9, str10, str11, str12, str13, str14, lan1, lan2, lan3, lan4, lan5, lan6, lan7, lan8, lan9, lan11, lan12, lan13],
  Concrete: [con1, con2, con3, con4, con5, con6, con7, con8],
  Masonry: [mas1, mas2, mas3, mas4, mas5, mas6, mas7, mas8],
  'Water Features': [wat1],//, wat2, wat3, wat4, wat5, wat6, wat7],
  'Fire Pits': [fir1, fir2, fir3, fir4, fir5, fir6, fir7, fir8],
  Structures: [str0, str1, str2, str3, str4, str5, str6, str7, str8, str9, str10, str11, str12, str13, str14],
  Landscape: [lan1, lan2, lan3, lan4, lan5, lan6, lan7, lan8, lan9, lan11, lan12, lan13],
};
function shuffleArray(array) {
  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function Gallery() {
  const [activeCategory, setActiveCategory] = useState("All");
  const [fullscreenIndex, setFullscreenIndex] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [shuffledImages, setShuffledImages] = useState([]);

  useEffect(() => {
    // Shuffle the images when the component mounts or when the category changes
    setShuffledImages(shuffleArray(categories[activeCategory]));
  }, [activeCategory]);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setFullscreenIndex(null); // Close fullscreen when changing category
    setDropdownVisible(false); // Close dropdown when changing category
  };

  const handleImageClick = (index) => {
    setFullscreenIndex(index);
    setDropdownVisible(false); // Close dropdown when clicking an image
  };

  const handleCloseFullscreen = () => {
    setFullscreenIndex(null);
  };

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div>
      {/* Dropdown menu for categories */}
      <div className="dropdown">
        <button className="dropbtn" onClick={handleDropdownToggle}>
          Category
        </button>
        {isDropdownVisible && (
          <div className="dropdown-content">
            {Object.keys(categories).map((category, index) => (
              <span key={index} onClick={() => handleCategoryChange(category)}>
                {category}
              </span>
            ))}
          </div>
        )}
      </div>

      {/* Gallery container */}
      <div className="gallery-container">
        {categories[activeCategory].map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className="gallery-image"
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>

        {/* Fullscreen modal */}
        {fullscreenIndex && (
          <FullscreenImage
            image={categories[activeCategory]}
            index={fullscreenIndex}
            onClose={handleCloseFullscreen}
          />
        )}
    </div>
  );
}