import { Link } from 'react-router-dom';
import '../css/Header.css'
import '../css/Home.css'
import logo from '../images/Cascadia Landscape (2).svg';
import title from '../images/Title.png';
import { DropDownMenu } from './DropDownMenu';

export function Header(){

    return(
        <header className="App-header">
            <div className='Logo'>
            <Link to={'/'}>
                <div>
                    <img src={logo} className="Cascadia-logo" alt="logo" />
                </div>
            </Link>
            </div>
            <div className='Menu'>
                <MenuFormat/>
            </div>
        </header>
    );
}


function MenuFormat(){
    return(
        <div className="Dropdown-Menu">
            <DropDownMenu/>
        </div>
    )
}